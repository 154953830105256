import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import dynamic from 'next/dynamic';
import React from 'react';
import { useSelector } from 'react-redux';

import Hidden from '@components/common/Hidden';
import PageWrapper from '@components/common/PageWrapper';
import HomeHooks from '@components/pages/home/Hooks';
import { mobileMode } from '@constants/config';
import { homePageComponentsTypes } from '@constants/homePageComponents';
import { PageTypes } from '@constants/pageTypes';
import { IHomePageComponentDataObject } from '@interfaces/home';
import { IStore } from '@interfaces/store';
import { checkPreviewMode } from '@utils/checkPreviewMode';

const Countdown = dynamic(()=>import('@components/pages/home/countdown'));
const HomepageBanner = dynamic(()=>import('@components/pages/home/banners/HomepageBanner'));
const BlogCarousel = dynamic(()=>import('./blog-carousel'));
const BrandCategoriesWrapper = dynamic(()=>import('./brand-categories/BrandCategoriesWrapper'));
const AdvantagesWrapper = dynamic(()=>import('./advantages/AdvantagesWrapper'), { ssr: false });
const BenefitsWrapper = dynamic(()=>import('./benefits/BenefitsWrapper'));
const Bestsellers = dynamic(()=>import('./bestsellers'), { ssr: false });
const DownloadMobileAppCard = dynamic(()=>import('../../common/cards/DownloadMobileAppCard'), { ssr: false });
const HeadingWrapper = dynamic(()=>import('./heading/HeadingWrapper'));
const PopularNew = dynamic(()=>import('./popular-categories/new'));
const LookbookCarousel = dynamic(()=>import('./lookbook-carousel'));
const TrendingProducts = dynamic(()=>import('./trending-products/'));
const RecentlyViewedWrapper = dynamic(()=>import('./recently-viewed'));
const EmptyLineWrapper = dynamic(()=>import('@components/pages/home/empty-line/EmptyLineWrapper'));
const HomepageVideoBanner = dynamic(()=>import('./video-banner/HomepageVideoBanner'));
const HomepageYoutubeBanner = dynamic(()=>import('./youtube-banner/HomepageYoutubeBanner'));
const TextWrapper = dynamic(()=>import('@components/pages/home/text/TextWrapper'));
const HomepagePortraitBanner = dynamic(()=>import('./portrait-banner/HomepagePortraitBanner'));
const HomepageSlider = dynamic(()=>import('./slider/'));
const HomepageProductsSlider = dynamic(()=>import('./products-slider'));
const CustomCards = dynamic(()=>import('./customСards'));
const CategoriesWrapper = dynamic(import('./gender-categories/CategoriesWrapper'));
const LastActiveNotification = dynamic(import('@components/pages/home/notifications/lastActive'));

const Home: React.FunctionComponent<{}> = () => {
  const pageData = useSelector((state: IStore) => state.pageData.data);
  const host = useSelector((state: IStore) => state.context.host);
  const {
    blog_posts,
    components: homePageComponents,
  } = pageData;
  const classes = useStyles();
  let mobileComponentIndex: number = -1;

  const addComponent = (component: IHomePageComponentDataObject, index: number) => {
    if (!checkPreviewMode(component, host)) {
      return null;
    }

    const element = findComponentElement(component);


    if (element === undefined || element === null) {
      return null;
    }

    const { show, showInMobile, showInApp } = component;

    if (showInMobile || mobileMode && showInApp) {
      mobileComponentIndex += 1;
    }

    return (!mobileMode || showInApp) && (
      <Hidden key={`homeComponent${index}`} mdUp={!show}
        mdDown={mobileMode ? !showInApp : !showInMobile}>
        {(mobileComponentIndex === 0 && [homePageComponentsTypes.COMPONENT_TYPE_SLIDER].includes(component.type)) ? (
          <div className={classes.firstComponent}>{React.cloneElement(element, {className: 'homePageComponent'})}</div>
        ) : React.cloneElement(element, {className: 'homePageComponent'})}
      </Hidden>
    );
  };

  const findComponentElement = (
    component: IHomePageComponentDataObject,
  ): React.FunctionComponentElement<any> | undefined => {
    switch (component.type) {

      case homePageComponentsTypes.COMPONENT_TYPE_BANNER:
        return <HomepageBanner component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_BLOG_POSTS:
        return <BlogCarousel component={component} blogPosts={blog_posts || []} />;
      case homePageComponentsTypes.COMPONENT_TYPE_BRANDS:
        return <BrandCategoriesWrapper component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_INFO:
        return <AdvantagesWrapper component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_BENEFITS:
        return <BenefitsWrapper component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_BESTSELLERS:
        return <Bestsellers component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_DOWNLOAD_APP_BANNER:
        return mobileMode ? undefined : <DownloadMobileAppCard component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_TITLE:
        return <HeadingWrapper component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_POPULAR_CATEGORIES:
        return <PopularNew component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_LOOKBOOK:
        return <LookbookCarousel component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_TRENDING_PRODUCTS:
        return <TrendingProducts component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_RECENTLY_VIEWED_PRODUCTS:
        return <RecentlyViewedWrapper component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_EMPTY_LINE:
        return <EmptyLineWrapper component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_VIDEO_BANNER:
        return <HomepageVideoBanner component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_YOUTUBE_BANNER:
        return <HomepageYoutubeBanner component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_TEXT:
        return <TextWrapper component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_PORTRAIT_BANNER:
        return <HomepagePortraitBanner component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_SLIDER:
        return <HomepageSlider component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_PRODUCTS_SLIDER:
        return <HomepageProductsSlider component={component} />;
      case homePageComponentsTypes.COMPONENT_TYPE_COUNTDOWN:
        return <Countdown component={component} />;
    }


    if (component.type.startsWith(homePageComponentsTypes.COMPONENT_TYPE_GENDER_CATEGORIES)) {
      return <CategoriesWrapper component={component} />;
    } else if (component.type.startsWith(homePageComponentsTypes.COMPONENT_TYPE_CUSTOM_CARDS)) {
      return <CustomCards component={component} />;
    }

    return undefined;
  };

  const getHomePageComponents = (isMobile: boolean): IHomePageComponentDataObject[] => {
    mobileComponentIndex = -1;
    if (homePageComponents) {
      if (isMobile) {
        return [...homePageComponents].map((item, index) => {
          let position = item.mobilepostition;
          if (position > 0) {
            position = position + 1;
          }
          return { ...item, mobilepostition: position + index };
        }).sort((a, b) => a.mobilepostition - b.mobilepostition);
      }

      return homePageComponents;
    }

    return [];
  };

  return (
    <PageWrapper pageType={PageTypes.home}>
      <HomeHooks />
      <div className={classes.home}>
        <Hidden mdDown={true}>
          {getHomePageComponents(false).map((component, index) => addComponent(component, index))}
        </Hidden>
        <Hidden mdUp={true}>
          {getHomePageComponents(true).map((component, index) => addComponent(component, index))}
        </Hidden>
      </div>
      <LastActiveNotification />
    </PageWrapper>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  firstComponent: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: '-32px',
    },
  },
  home: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    paddingTop: 40,
    [theme.breakpoints.down('md')]: {
      paddingTop: 32,
    },
  },
}));

export default Home;
