import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { acPageUnmount } from '@actions/acPage';
import { PageTypes } from '@constants/pageTypes';
import { TThunkDispatch } from '@interfaces/index';

export interface IPageWrapperProps {
  pageType: PageTypes;
}

const PageWrapper: React.FunctionComponent<IPageWrapperProps & PropsWithChildren> = ({ children, pageType }) => {
  const dispatch = useDispatch<TThunkDispatch>();
  const onUnmount = useCallback(() => dispatch(acPageUnmount(pageType)), [dispatch, pageType]);

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, []);

  return (<>
    {children}</>);
};

export default PageWrapper;
