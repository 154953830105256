import React, { useEffect } from 'react';

import { ESideMenuPage } from '@interfaces/page/store';
import usePageStore from '@reducers/page/useState';

const HomeHooks: React.FunctionComponent<{}> = () => {
  const { onSideMenuHandle, onSideMenuPageHandle } = usePageStore();

  useEffect(() => {
    const url = window.location.hash;
    const hash = url.substring(url.indexOf('#') + 1);
    if (hash && hash === 'login') {
      onSideMenuPageHandle(ESideMenuPage.login);
      onSideMenuHandle(true);
    }
  }, []);

  return null;
};


export default HomeHooks;
